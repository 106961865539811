import { request } from './request'

// 获取一级机构列表
function selectFirstListPage(data) {
    return request({
        url: '/org/webOrg/selectListPage',
        method: 'POST',
        data: data
    })
}
// 获取二级机构列表
function selectSecondListPage(data) {
    return request({
        url: '/org/webSecondOrg/selectListPage',
        method: 'POST',
        data: data
    })
}
// 查询一级机构信息
function getWebOrg(data) {
    return request({
        url: '/org/webOrg/getWebOrg',
        method: 'POST',
        data: data
    })
}
// 查询省份或者城市数据
function selectAreaList(data) {
    return request({
        url: '/org/sysArea/selectAreaList',
        method: 'POST',
        data: data
    })
}
// 获取当前已审核使用的机构信息
function getCurrentUserUnit(data) {
    return request({
        url: '/org/userUnit/getCurrentUserUnit',
        method: 'POST',
        data: data
    })
}
// 修改当前正在使用的机构信息
function userUnitSave(data) {
    return request({
        url: '/org/userUnit/userUnitSave',
        method: 'POST',
        data: data
    })
}
// 获取当前机构管理员列表
function getUserUnitAdmin(data) {
    return request({
        url: '/org/userUnit/getUserUnitAdmin',
        method: 'POST',
        data: data
    })
}
// 更换主管理员
function changeMainAdmin(data) {
    return request({
        url: '/org/userUnit/changeMainAdmin',
        method: 'POST',
        data: data
    })
}
// 移除子管理员
function deleteSubAdmin(data) {
    return request({
        url: '/org/userUnit/deleteSubAdmin',
        method: 'POST',
        data: data
    })
}
// 用户所在的已审核通过机构列表
function getUserUnitList(data) {
    return request({
        url: '/org/userUnit/getUserUnitList',
        method: 'POST',
        data: data
    })
}
// 根据id查找机构申请信息
function getUserUnitData(data) {
    return request({
        url: '/org/userUnit/getUserUnit',
        method: 'POST',
        data: data
    })
}
// 切换当前机构
function changeUnit(data) {
    return request({
        url: '/org/user/changeUnit',
        method: 'POST',
        data: data
    })
}
// 用户所关联的机构列表
function selectUserListPage(data) {
    return request({
        url: '/org/userUnit/selectListPage',
        method: 'POST',
        data: data
    })
}
// 获取单个账号最多允许5条申请记录（包含已通过、审核中、被拒绝）的数量，超过则不允许绑定新机构
function selectApplyUserUnitCnt(data) {
    return request({
        url: '/org/user/selectApplyUserUnitCnt',
        method: 'POST',
        data: data
    })
}
// 邀请子管理员
function saveSubAdmin(data) {
    return request({
        url: '/org/user/saveSubAdmin',
        method: 'POST',
        data: data
    })
}
// 邀请子管理员
function userUnitDelete(data) {
    return request({
        url: '/org/userUnit/userUnitDelete',
        method: 'POST',
        data: data
    })
}
// 邀请子管理员
function addSubAdminSendSmsCode(data) {
    return request({
        url: '/org/user/addSubAdminSendSmsCode',
        method: 'POST',
        data: data
    })
}

//机构专属页

//根据机构ID查询配置
function getOrgExclusive(data) {
    return request({
        url: '/org/orgExclusivePageConfig/getOrgExclusivePageConfigByOrgId',
        method: 'POST',
        data: data
    })
}
//保存机构专属页配置
function orgExclusiveConfigSave(data) {
    return request({
        url: '/org/orgExclusivePageConfig/orgExclusivePageConfigSave',
        method: 'POST',
        data: data
    })
}

export default {
    selectFirstListPage,
    selectSecondListPage,
    getWebOrg,
    selectAreaList,
    getCurrentUserUnit,
    userUnitSave,
    getUserUnitAdmin,
    changeMainAdmin,
    deleteSubAdmin,
    getUserUnitList,
    getUserUnitData,
    changeUnit,
    selectUserListPage,
    saveSubAdmin,
    userUnitDelete,
    addSubAdminSendSmsCode,
    selectApplyUserUnitCnt,
    //机构专属页
    getOrgExclusive,
    orgExclusiveConfigSave
}
