const Crypto = require('crypto-js') // 引用AES源码js
// 默认的 KEY 与 iv 如果没有给
const key = Crypto.enc.Utf8.parse('AOWQ4P0YEC4YXUKS') // 十六位十六进制数作为密钥
const iv = Crypto.enc.Utf8.parse('O3V2GCL1K2HNZ9Y7') // 十六位十六进制数作为密钥偏移量
 
 
export default {
  // 解密方法
  Decrypt(word) {
    const encryptedHexStr = Crypto.enc.Hex.parse(word)
    const srcs = Crypto.enc.Base64.stringify(encryptedHexStr)
    const decrypt = Crypto.AES.decrypt(srcs, key, { iv: iv, mode: Crypto.mode.CBC, padding: Crypto.pad.Pkcs7 })
    const decryptedStr = decrypt.toString(Crypto.enc.Utf8)
    return decryptedStr.toString()
  },
  // 加密方法
  Encrypt(word) {
    const srcs = Crypto.enc.Utf8.parse(word)
    const encrypted = Crypto.AES.encrypt(srcs, key, { iv: iv, mode: Crypto.mode.CBC, padding: Crypto.pad.Pkcs7 })
    return encrypted.ciphertext.toString().toUpperCase()
  },
  // MD5 加密
  EncryptMd5(word) {
	  return Crypto.MD5(word).toString()
  },
}
