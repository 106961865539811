import {request} from "./request";
import config from "@/config";

// 获取天气
function getWeather(data) {
  return request({
    url: '/org/weather/getWeather',
    method: 'POST',
    data
  })
}

// 获取首页图表数据
function getHomeCountData(data) {
  return request({
    url: '/org/home/getHomeCountData',
    method: 'POST',
    data
  })
}

// 获取首页活动热度数据
function getHomeRankList(data) {
  return request({
    url: '/org/act/selectRankList',
    method: 'POST',
    data
  })
}

// 获取全部活动热度数据
function getAllHomeRankList(data) {
  return request({
    url: '/org/act/selectAllRankList',
    method: 'POST',
    data
  })
}

// 获取首页活动热度数据
function getHomeJoinRankList(data) {
  return request({
    url: '/org/act/selectJoinRankList',
    method: 'POST',
    data
  })
}


// AI交互
// AI文本生成图片
function wordsToImageAPI(data) {
  return request({
    url: '/org/wordsToImage/wordsToImage',
    method: 'POST',
    data
  })
}
// AI文本生成图片查询
function fetchTaskImageAPI(data) {
  return request({
    url: '/org/wordsToImage/fetchTask',
    method: 'POST',
    data
  })
}

// 统计当前用户剩余灵感值和收藏图片数量
function countLimitAndCollectAPI(data) {
  return request({
    url: '/org/wordsToImage/countLimitAndCollect',
    method: 'POST',
    data
  })
}

// 收藏/取消AI图片
function collectAiImageAPI(data) {
  return request({
    url: '/org/wordsToImage/collectImage',
    method: 'POST',
    data
  })
}

// 获取AI绘图的数据
function selectWordsToImageListPage(data) {
  return request({
    url: '/org/wordsToImage/selectListPage',
    method: 'POST',
    data
  })
}

// 获取当前用户系统指引状态
function getCurrentUserSysGuideShowLogAPI(data) {
  return request({
    url: '/org/sysGuideShowLog/getCurrentUserSysGuideShowLog',
    method: 'POST',
    data
  })
}

// 当前用户系统指引状态保存/修改
function sysGuideShowLogSaveAPI(data) {
  return request({
    url: '/org/sysGuideShowLog/sysGuideShowLogSave',
    method: 'POST',
    data
  })
}

// 获取当前用户每天可免费使用绘画次数
function getCurrentUserDayLimitAPI(data) {
  return request({
    url: '/org/wordToImageUserDayLimit/getCurrentUserDayLimit',
    method: 'POST',
    data
  })
}
export default {
	getWeather,
  getHomeCountData,
  getHomeRankList,
  getAllHomeRankList,
  getHomeJoinRankList,
  wordsToImageAPI,
  fetchTaskImageAPI,
  countLimitAndCollectAPI,
  collectAiImageAPI,
  selectWordsToImageListPage,
  getCurrentUserSysGuideShowLogAPI,
  sysGuideShowLogSaveAPI,
  getCurrentUserDayLimitAPI,
}
