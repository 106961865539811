const { request } = require('./request')

// 查询活动信息
function getAct(data) {
    return request({
        url: '/org/act/getAct',
        method: 'POST',
        data
    })
}

// 新增/修改活动信息
function saveAct(data) {
    return request({
        url: '/org/act/actSave',
        method: 'POST',
        data
    })
}

// 活动数据列表
function selectActList(data) {
    return request({
        url: '/org/act/selectListPage',
        method: 'POST',
        data
    })
}

// 活动定制化配置，获取
function getActConfig(data) {
    return request({
        url: '/org/actConfig/getActConfig',
        method: 'POST',
        data
    })
}

// 活动定制化配置，新增或修改
function saveActConfig(data) {
    return request({
        url: '/org/actConfig/actConfigSave',
        method: 'POST',
        data
    })
}

// 定制类活动表单提交
function actCustomOrderSave(data) {
    return request({
        url: '/org/actCustomOrder/actCustomOrderSave',
        method: 'POST',
        data
    })
}

// 定制类活动数据列表
function selectCustomListPage(data) {
    return request({
        url: '/org/actCustomOrder/selectListPage',
        method: 'POST',
        data
    })
}

// 删除当前条定制化活动数据
function actCustomOrderDelete(data) {
    return request({
        url: '/org/actCustomOrder/actCustomOrderDelete',
        method: 'POST',
        data
    })
}

// 查询当前条定制化活动数据
function getActCustomOrder(data) {
    return request({
        url: '/org/actCustomOrder/getActCustomOrder',
        method: 'POST',
        data
    })
}

// 校验活动URL是否存在
function checkActUrlAPI(data) {
    return request({
        url: '/org/act/ifExistsActUrl',
        method: 'POST',
        data
    })
}

// 获取作品审核活动列表
function selectApproveDataListAPI(data) {
    return request({
        url: '/org/actWork/selectApproveDataListPage',
        method: 'POST',
        data
    })
}

// 活动作品审核/保存
function collectActWorkSave(data) {
    return request({
        url: '/org/actWork/actWorkSave',
        method: 'POST',
        data
    })
}

// 活动作品导出数据
function collectActWorkExportData(data) {
    return request({
        url: '/org/actWork/exportData',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 校验参与组织活动权限(checkResult=1-已参与，2-没权限，3-有权限，4-本机构活动)
function checkActJoinAuthAPI(data) {
    return request({
        url: '/org/actJoin/joinAuthCheck',
        method: 'POST',
        data
    })
}

// 活动参与组织机构保存
function actJoinSaveAPI(data) {
    return request({
        url: '/org/actJoin/actJoinSave',
        method: 'POST',
        data
    })
}

// 获取活动参与组织机构列表
function actJoinListAPI(data) {
    return request({
        url: '/org/act/selectJoinListPage',
        method: 'POST',
        data
    })
}

// 获取活动数据分页列表
function selectActDataListPage(data) {
    return request({
        url: '/org/act/selectActDataListPage',
        method: 'POST',
        data
    })
}

// 获取活动组别列表
function selectActGroupListPage(data) {
    return request({
        url: '/org/actGroup/selectListPage',
        method: 'POST',
        data
    })
}

// 获取活动主题列表
function selectActThemeListPage(data) {
    return request({
        url: '/org/actTheme/selectListPage',
        method: 'POST',
        data
    })
}

// 获取活动类别列表
function selectActWorkTypeListPage(data) {
    return request({
        url: '/org/actWorkType/selectListPage',
        method: 'POST',
        data
    })
}

// 获取活动报名数据列表
function selectActEnrollListPage(data) {
    return request({
        url: '/org/actEnroll/selectListPage',
        method: 'POST',
        data
    })
}

// 报名数据导出
function actEnrollExportData(data) {
    return request({
        url: '/org/actEnroll/exportData',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 获取活动作品数据列表
function selectActWorkListPage(data) {
    return request({
        url: '/org/actWork/selectListPage',
        method: 'POST',
        data
    })
}
// 判断作品数据是否显示投票数量
function getActStatusInfoAPI(data) {
    return request({
        url: '/org/act/getActStatusInfo',
        method: 'POST',
        data
    })
}
// 作品数据导出
function actWorkExportData(data) {
    return request({
        url: '/org/actWork/exportData',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
// 查找作品信息
function getCollectActWorkData(data) {
    return request({
        url: '/org/actWork/getActWork',
        method: 'POST',
        data
    })
}
// 机构报名数据
function selectActOrgEnrollListPage(data) {
    return request({
        url: '/org/act/selectActOrgDataListPage',
        method: 'POST',
        data
    })
}
// 机构报名数据导出
function ActOrgListExportData(data) {
    return request({
        url: '/org/act/exportActOrgData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 打卡数据
function selectActClockListPage(data) {
    return request({
        url: '/org/actClock/selectListPage',
        method: 'POST',
        data
    })
}
// 打卡数据导出
function actClockListExportData(data) {
    return request({
        url: '/org/actClock/exportData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 选手数据导出
function actUserClockDataExportData(data) {
    return request({
        url: '/org/actClock/exportUserClockData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
// 查找作品源文件列表
function selectWorkAnnexListPage(data) {
    return request({
        url: '/org/actWorkFile/selectListPage',
        method: 'POST',
        data
    })
}

// 作品推选
// 获取作品推选系统列表
function selectActWorkElectListPage(data) {
    return request({
        url: '/org/actWork/selectWorkSelectSysListPage',
        method: 'POST',
        data
    })
}
// 获取当前推选系统数据统计情况
function getOrgWorksElectCountData(data) {
    return request({
        url: '/org/actWork/getOrgWorkSelectCount',
        method: 'POST',
        data
    })
}
// 获取活动报名选送单位列表
function selectEnrollOrgListPage(data) {
    return request({
        url: '/org/actEnroll/selectEnrollOrgList',
        method: 'POST',
        data
    })
}
// 推选页面获取活动作品列表
function selectWorkElectListPage(data) {
    return request({
        url: '/org/actWork/selectWorkSelectListPage',
        method: 'POST',
        data
    })
}
// 作品推选保存
function actWorkElectSaveAPI(data) {
    return request({
        url: '/org/actWork/actWorkSelectSave',
        method: 'POST',
        data
    })
}
// 单位推选情况
function getOrgWorkSelectCountList(data) {
    return request({
        url: '/org/actWork/getOrgWorkSelectCountList',
        method: 'POST',
        data
    })
}
// 作品推选情况列表
function getAllWorkElectListPage(data) {
    return request({
        url: '/org/actWork/getAllWorkSelectListPage',
        method: 'POST',
        data
    })
}
// 作品推选情况数据统计
function selectWorkElectCount(data) {
    return request({
        url: '/org/actWork/selectWorkSelectCount',
        method: 'POST',
        data
    })
}
// 校验活动是否存在报名数据
function ifExistsActEnrollDataAPI(data) {
    return request({
        url: '/org/actEnroll/ifExistsActEnrollData',
        method: 'POST',
        data
    })
}
// 校验活动是否存在作品数据
function ifExistsActWorkDataAPI(data) {
    return request({
        url: '/org/actWork/ifExistsActWorkData',
        method: 'POST',
        data
    })
}

// 检查当前活动是否允许重新编号
function checkIsAllowCurrentActRecodeAPI(data) {
    return request({
        url: '/org/actVoteContent/checkIsAllowCurrentActRecode',
        method: 'POST',
        data
    })
}

// 获取投票结果分页列表
function actVoteContentSelectListPageAPI(data) {
    return request({
        url: '/org/actVoteContent/selectListPage',
        method: 'POST',
        data
    })
}
// 导出投票数据
function actVoteContentExportDataAPI(data) {
    return request({
        url: '/org/actVoteContent/exportData',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 检查内容是否已被投过票
function actVoteCheckContentAPI(data) {
    return request({
        url: '/org/actVoteContent/checkContent',
        method: 'POST',
        data
    })
}

// 获取问卷列表
function selectQaLibListAPI(data) {
    return request({
        url: '/org/actQuestion/selectListPage',
        method: 'POST',
        data
    })
}

// 获取机构模版、系统模版
function selectOrgOSysQaLibListAPI(data) {
    return request({
        url: '/org/actQuestionLib/selectListPage',
        method: 'POST',
        data
    })
}

// 新增、删除问卷 【问卷列表】
function actQaLibrarySaveAPI(data) {
    return request({
        url: '/org/actQuestion/actQuestionSave',
        method: 'POST',
        data
    })
}

// 新增、编辑、删除问卷 【机构模版】
function actQaOrgSaveAPI(data) {
    return request({
        url: '/org/actQuestionLib/actQuestionLibSave',
        method: 'POST',
        data
    })
}

// 复制问卷 【机构模版】
function actQaOrgCopyAPI(data) {
    return request({
        url: '/org/actQuestionLib/copy',
        method: 'POST',
        data
    })
}

// 根据id查询问卷 【问卷列表】
function getActQaListByIdAPI(data) {
    return request({
        url: '/org/actQuestion/getActQuestion',
        method: 'POST',
        data
    })
}

// 根据id查询问卷 【机构模版】
function getActQaOrgByIdAPI(data) {
    return request({
        url: '/org/actQuestionLib/getActQuestionLib',
        method: 'POST',
        data
    })
}

// 根据问卷code查询问卷
function getByQuestionCodeAPI(data) {
    return request({
        url: '/org/actQuestion/getByQuestionCode',
        method: 'POST',
        data
    })
}

// 删除时检查是否有答题数据
function questionDelCheckByCodeAPI(data) {
    return request({
        url: '/org/actQuestionAnswer/checkByCode',
        method: 'POST',
        data
    })
}

// 编辑时检查是否有答题数据
function questionEditCheckByCodeAPI(data) {
    return request({
        url: '/org/actQuestionAnswer/checkByCodeInEdit',
        method: 'POST',
        data
    })
}

// 清除作答数据
function questionClearAnswerDataAPI(data) {
    return request({
        url: '/org/actQuestion/clearAnswerData',
        method: 'POST',
        data
    })
}

// 机构模版问卷使用
function useQaTemplateQaAPI(data) {
    return request({
        url: '/org/actQuestionLib/useTemplateQuestion',
        method: 'POST',
        data
    })
}

// 问卷列表复用
function reuseQaAPI(data) {
    return request({
        url: '/org/actQuestion/copy',
        method: 'POST',
        data
    })
}

// 问卷列表使用
function qaListUseQaAPI(data) {
    return request({
        url: '/org/actQuestion/actAndQuesAssociat',
        method: 'POST',
        data
    })
}

// 获取知识问答答题记录分页列表
function getQaRecordListAPI(data) {
    return request({
        url: '/org/actQuestionAnswer/selectListPage',
        method: 'POST',
        data
    })
}

// 根据id查询知识问答答题记录
function getActQaRecordByIdAPI(data) {
    return request({
        url: '/org/actQuestionAnswer/getActQuestionAnswer',
        method: 'POST',
        data
    })
}

// 知识问答答题记录保存（批卷）
function saveActQaRecordAPI(data) {
    return request({
        url: '/org/actQuestionAnswer/actQuestionAnswerSave',
        method: 'POST',
        data
    })
}

// 获取知识问答答题报名数据分页列表
function selectQaEnrollListAPI(data) {
    return request({
        url: '/org/actQuestionAnswer/selectEnrollListPage',
        method: 'POST',
        data
    })
}

// 导出答题报名数据
function exportQaEnrollDataAPI(data) {
    return request({
        url: '/org/actQuestionAnswer/exportEnrollData',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 获取活动问卷列表【问卷数据、答卷数据】
function getActQaListAPI(data) {
    return request({
        url: '/org/actQuestion/getActQuestionList',
        method: 'POST',
        data
    })
}

// 导出答卷数据
function exportAnswerDataAPI(data) {
    return request({
        url: '/org/actQuestionAnswer/exportAnswerData',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
// 查找评审系统信息
function getSysInfoDataAPI(data) {
    return request({
        url: '/score/sysInfo/getSysInfo',
        method: 'POST',
        data
    })
}

// 查看打卡记录列表
function selectActClockListAPI(data) {
    return request({
        url: '/org/actClock/selectActClockListPage',
        method: 'POST',
        data
    })
}

// ===== 会议论坛 =====
// 获取会议论坛会议议程分页列表
function selectForumAgendaListPageAPI(data) {
    return request({
        url: '/org/actForumAgenda/selectListPage',
        method: 'POST',
        data
    })
}

// 获取会议论坛议程会场类型分页列表
function selectForumVenueTypeListPageAPI(data) {
    return request({
        url: '/org/actForumVenueType/selectListPage',
        method: 'POST',
        data
    })
}

// 获取会议论坛议程会场类型分页列表
function actForumVenueTypeSaveAPI(data) {
    return request({
        url: '/org/actForumVenueType/actForumVenueTypeSave',
        method: 'POST',
        data
    })
}

// 获取会议论坛议程会场类型分页列表
function actForumAgendaSaveAPI(data) {
    return request({
        url: '/org/actForumAgenda/actForumAgendaSave',
        method: 'POST',
        data
    })
}

// 通过xlsx批量导入议程
function actForumAgendaImportAPI(data, id) {
    return request({
        headers: { 'Content-Type': 'multipart/form-data' },
        url: '/org/actForumAgenda/importByXlsx?actId=' + id,
        method: 'POST',
        data
    })
}

// 下载议程批量导入模板文件
function downForumAgendaImportTemplateAPI(data) {
    return request({
        url: '/org/actForumAgenda/downImportTemplate',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 获取会议论坛嘉宾信息分页列表
function selectForumGuestListPageAPI(data) {
    return request({
        url: '/org/actForumGuest/selectListPage',
        method: 'POST',
        data
    })
}

// 会议论坛嘉宾信息保存(新增或修改)
function actForumGuestSaveAPI(data) {
    return request({
        url: '/org/actForumGuest/actForumGuestSave',
        method: 'POST',
        data
    })
}

// 通过xlsx批量导入嘉宾信息
function actForumGuestImportAPI(data, id) {
    return request({
        headers: { 'Content-Type': 'multipart/form-data' },
        url: '/org/actForumGuest/importByXlsx?actId=' + id,
        method: 'POST',
        data
    })
}

// 下载嘉宾信息批量导入模板文件
function downForumGuestImportTemplateAPI(data) {
    return request({
        url: '/org/actForumGuest/downImportTemplate',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 通过zip批量导入嘉宾头像
function actForumGuestAvatarImportAPI(data, id) {
    return request({
        headers: { 'Content-Type': 'multipart/form-data' },
        url: '/org/actForumGuest/batchUploadGuestAvatars?actId=' + id,
        method: 'POST',
        data
    })
}

// 根据id查询会议详情
function getActForumAgendaAPI(data, id) {
    return request({
        url: '/org/actForumAgenda/getActForumAgenda',
        method: 'POST',
        data
    })
}

// 获取会议模板列表
function selectActForumLibListAPI(data, id) {
    return request({
        url: '/org/actForumPageLib/selectListPage',
        method: 'POST',
        data
    })
}

// 获取微信小程序二维码
function getWXQrcode(data) {
    return request({
        url: '/org/wxMini/getQRCode',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
// ===== end =====

module.exports = {
    getAct,
    saveAct,
    selectActList,
    getActConfig,
    saveActConfig,
    actCustomOrderSave,
    selectCustomListPage,
    actCustomOrderDelete,
    getActCustomOrder,
    checkActUrlAPI,
    selectApproveDataListAPI,
    collectActWorkSave,
    collectActWorkExportData,
    checkActJoinAuthAPI,
    actJoinSaveAPI,
    actJoinListAPI,
    selectActDataListPage,
    selectActGroupListPage,
    selectActEnrollListPage,
    actEnrollExportData,
    selectActThemeListPage,
    selectActWorkTypeListPage,
    selectActWorkListPage,
    getActStatusInfoAPI,
    actWorkExportData,
    getCollectActWorkData,
    selectActOrgEnrollListPage,
    ActOrgListExportData,
    selectActClockListPage,
    actClockListExportData,
    actUserClockDataExportData,
    selectWorkAnnexListPage,
    selectActWorkElectListPage,
    getOrgWorksElectCountData,
    selectEnrollOrgListPage,
    selectWorkElectListPage,
    actWorkElectSaveAPI,
    getOrgWorkSelectCountList,
    getAllWorkElectListPage,
    selectWorkElectCount,
    ifExistsActEnrollDataAPI,
    ifExistsActWorkDataAPI,
    checkIsAllowCurrentActRecodeAPI,
    actVoteContentSelectListPageAPI,
    actVoteContentExportDataAPI,
    actVoteCheckContentAPI,
    selectQaLibListAPI,
    actQaLibrarySaveAPI,
    actQaOrgCopyAPI,
    getActQaListByIdAPI,
    getByQuestionCodeAPI,
    questionDelCheckByCodeAPI,
    questionEditCheckByCodeAPI,
    questionClearAnswerDataAPI,
    selectOrgOSysQaLibListAPI,
    actQaOrgSaveAPI,
    getActQaOrgByIdAPI,
    useQaTemplateQaAPI,
    reuseQaAPI,
    qaListUseQaAPI,
    getQaRecordListAPI,
    getActQaRecordByIdAPI,
    saveActQaRecordAPI,
    selectQaEnrollListAPI,
    exportQaEnrollDataAPI,
    getActQaListAPI,
    exportAnswerDataAPI,
    getSysInfoDataAPI,
    selectActClockListAPI,
    getWXQrcode,
    // 会议论坛
    selectForumAgendaListPageAPI,
    selectForumVenueTypeListPageAPI,
    actForumVenueTypeSaveAPI,
    actForumAgendaSaveAPI,
    actForumAgendaImportAPI,
    downForumAgendaImportTemplateAPI,
    selectForumGuestListPageAPI,
    actForumGuestSaveAPI,
    actForumGuestImportAPI,
    downForumGuestImportTemplateAPI,
    actForumGuestAvatarImportAPI,
    getActForumAgendaAPI,
    selectActForumLibListAPI
}
