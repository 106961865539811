import { request } from './request'
// 查询会议论坛核心数据
function selectCoreDataAPI(data) {
    return request({
        url: '/org/actForumFunVisitData/selectCoreData',
        method: 'POST',
        data
    })
}
//导出会议议程访问数据
function exportAgendaDataAPI(data) {
    return request({
        url: '/org/actForumFunVisitData/exportAgendaVisitDataList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
//导出会议功能使用数据
function exportFunUseDataAPI(data) {
    return request({
        url: '/org/actForumFunVisitData/exportFunUseDataList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
//导出会议嘉宾访问数据
function exportGuestDataAPI(data) {
    return request({
        url: '/org/actForumFunVisitData/exportGuestVisitDataList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
//查询会议议程访问数据
function selectAgendaDataAPI(data) {
    return request({
        url: '/org/actForumFunVisitData/selectAgendaVisitDataList',
        method: 'POST',
        data
    })
}
//查询会议嘉宾访问数据
function selectGuestVisitAPI(data) {
    return request({
        url: '/org/actForumFunVisitData/selectGuestVisitDataList',
        method: 'POST',
        data
    })
}
//查询会议功能使用数据
function selectFunUseDataAPI(data) {
    return request({
        url: '/org/actForumFunVisitData/selectFunUseDataList',
        method: 'POST',
        data
    })
}

//查询推荐资源数据统计
function countAccessDataAPI(data) {
    return request({
        url: '/org/actRecommendContentVisitData/countAccessData',
        method: 'POST',
        data
    })
}
//导出推荐资源数据统计
function exportCountAccessAPI(data) {
    return request({
        url: '/org/actRecommendContentVisitData/exportCountAccessData',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

//查询推荐资源数据统计列表
function countResourceClickAPI(data) {
    return request({
        url: '/org/actRecommendContentVisitData/countResourceClickData',
        method: 'POST',
        data
    })
}
//导出推荐资源数据统计列表
function exportCountResourceAPI(data) {
    return request({
        url: '/org/actRecommendContentVisitData/exportCountResourceClickData',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
export default {
    selectCoreDataAPI,
    selectGuestVisitAPI,
    selectFunUseDataAPI,
    selectAgendaDataAPI,
    exportFunUseDataAPI,
    exportGuestDataAPI,
    exportAgendaDataAPI,
    countAccessDataAPI,
    exportCountAccessAPI,
    countResourceClickAPI,
    exportCountResourceAPI
}
