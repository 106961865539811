import Vue from 'vue'

export default {}.install = (Vue, options = {}) => {
    Vue.directive('autocompleteLoadMore', {
        inserted(el, binding) {
            // 获取element-ui定义好的scroll盒子
            const SELECTDOWN_DOM = el.querySelector('.el-autocomplete-suggestion .el-autocomplete-suggestion__wrap')
            SELECTDOWN_DOM.addEventListener('scroll', function () {
                const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight
                if (CONDITION) {
                    binding.value()
                }
            })
        }
    })
}