import { request } from './request'

// 获取机构活动作品下载任务分页列表
function selectActWorkDownTaskListAPI(data) {
    return request({
        url: '/org/orgActWorkDownTask/selectListPage',
        method: 'POST',
        data: data
    })
}

// 获取当前机构总流量及已用流量
function getOrgNetFlowInfoAPI(data) {
    return request({
        url: '/org/orgNetFlowDetail/getOrgNetFlowInfo',
        method: 'POST',
        data: data
    })
}

// 机构活动作品下载任务保存(新增或修改)
function orgActWorkDownTaskSaveAPI(data) {
    return request({
        url: '/org/orgActWorkDownTask/orgActWorkDownTaskSave',
        method: 'POST',
        data: data
    })
}

// 获取机构活动作品下载任务明细附件分页列表
function selectOrgActWorkDownTaskZipFileListAPI(data) {
    return request({
        url: '/org/orgActWorkDownTaskZipFile/selectListPage',
        method: 'POST',
        data: data
    })
}

export default {
    selectActWorkDownTaskListAPI,
    getOrgNetFlowInfoAPI,
    orgActWorkDownTaskSaveAPI,
    selectOrgActWorkDownTaskZipFileListAPI
}
