const { request } = require('./request')

// 帮助中心目录列表
function treeWithArticlesAPI(data) {
    return request({
        url: '/org/sysDocDirectory/treeWithArticles',
        method: 'POST',
        data
    })
}
// 关键词搜索获取系统文档信息列表
function keyWordSelectListAPI(data) {
    return request({
        url: '/org/sysDocInfo/keyWordSelectList',
        method: 'POST',
        data
    })
}
// 获取搜索历史列表
function selectDocSearchHisListPageAPI(data) {
    return request({
        url: '/org/sysDocSearchHis/selectListPage',
        method: 'POST',
        data
    })
}
// 删除搜索历史
function sysDocSearchHisDeleteAPI(data) {
    return request({
        url: '/org/sysDocSearchHis/sysDocSearchHisDelete',
        method: 'POST',
        data
    })
}
// 查询文章详情
function getSysDocInfoAPI(data) {
    return request({
        url: '/org/sysDocInfo/getSysDocInfo',
        method: 'POST',
        data
    })
}
// 新增或修改意见反馈
function sysDocFeebackSaveAPI(data) {
    return request({
        url: '/org/sysDocFeeback/sysDocFeebackSave',
        method: 'POST',
        data
    })
}
// 新增用户搜索历史
export const sysDocSearchHisSaveAPI = (data) => {
    return request({
        url: '/org/sysDocSearchHis/sysDocSearchHisSave',
        method: 'POST',
        data
    })
}

export default {
    treeWithArticlesAPI,
    keyWordSelectListAPI,
    selectDocSearchHisListPageAPI,
    sysDocSearchHisDeleteAPI,
    getSysDocInfoAPI,
    sysDocFeebackSaveAPI,
    sysDocSearchHisSaveAPI,
}