import { request } from './request'
// 资源管理
// 获取课程资源列表
function selectResourcesListPageAPI(data) {
    return request({
        url: '/org/course/selectListPage',
        method: 'POST',
        data: data
    })
}

// 批量导入课程资源基本信息
function importResourcesByXlsxAPI(data) {
    return request({
        headers: { 'Content-Type': 'multipart/form-data' },
        url: '/org/course/importByXlsx',
        method: 'POST',
        data: data
    })
}

// 课程资源基本信息导入模板下载
function importResourcesTemplateAPI(data) {
    return request({
        url: '/org/course/downImportTemplate',
        method: 'POST',
        data: data,
        responseType: 'blob'
    })
}

//课程资源保存(新增或修改)
function courseSaveAPI(data) {
    return request({
        url: '/org/course/courseSave',
        method: 'POST',
        data: data
    })
}

//批量修改课程资源状态
function batchUpdateCourseStatusAPI(data) {
    return request({
        url: '/org/course/batchUpdateCourseStatus',
        method: 'POST',
        data: data
    })
}

// 根据ID查询课程资源
function selectResourceDetailAPI(data) {
    return request({
        url: '/org/course/getCourse',
        method: 'POST',
        data: data
    })
}
// 导出课程
function exportCourseDataAPI(data) {
    return request({
        url: '/org/course/export',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}
export default {
    //课程资源
    selectResourcesListPageAPI,
    importResourcesByXlsxAPI,
    importResourcesTemplateAPI,
    courseSaveAPI,
    batchUpdateCourseStatusAPI,
    selectResourceDetailAPI,
    exportCourseDataAPI
}
