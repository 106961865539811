import {request} from "./request";

// 获取系统消息未读数量
function getUserUnReadMessageCnt(data) {
    return request({
        url: '/org/sysMessage/getUserUnReadMessageCnt',
        method: 'POST',
        data: data
    })
}
// 获取系统消息列表
function selectSysMessageListPage(data) {
    return request({
        url: '/org/sysMessage/selectListPage',
        method: 'POST',
        data: data
    })
}
// 当前用户全部系统消息标记为已读
function receiveAllSysMessageSave(data) {
    return request({
        url: '/org/sysMessageReceive/receiveAllSysMessageSave',
        method: 'POST',
        data: data
    })
}

export default {
    getUserUnReadMessageCnt,
    selectSysMessageListPage,
    receiveAllSysMessageSave,
}