import { request } from "./request";

// 获取验证码
function sendLoginSmsCode(data) {
    return request({
        url: 'org/login/sendLoginSmsCode',
        method: 'POST',
        data: data
    })
}
// 验证码登录
function loginBySmsCode(data) {
    return request({
        url: 'org/login/loginBySmsCode',
        method: 'POST',
        data: data
    })
}
// 账号密码登录
function loginByPwd(data) {
    return request({
        url: '/org/login/loginByPwd',
        method: 'POST',
        data: data
    })
}
// 设置密码
function setPassword(data) {
    return request({
        url: '/org/user/setPassword',
        method: 'POST',
        data: data
    })
}
// 退出登录
function logout(data) {
    return request({
        url: '/org/user/logout',
        method: 'POST',
        data: data
    })
}
// 修改密码
function editPassword(data) {
    return request({
        url: '/org/user/editPassword',
        method: 'POST',
        data: data
    })
}
// 更换手机号码
function changeUserPhone(data) {
    return request({
        url: '/org/user/changeUserPhone',
        method: 'POST',
        data: data
    })
}
// 获取登录页banner
function getSysConfig(data) {
    return request({
        url: '/org/sysConfig/getSysConfig',
        method: 'POST',
        data: data
    })
}
// 通过官网token自动登录
function loginByWebToken(data) {
    return request({
        url: '/org/login/loginByWebToken',
        method: 'POST',
        data: data
    })
}
// 统一认证登录
function loginByUniAPI(data) {
    return request({
        url: '/org/login/ssoLogin',
        method: 'POST',
        data
    })
}

// 统一认证平台同步用户信息
function syncCurrentUniUserInfoAPI(data) {
    return request({
        url: '/org/user/syncCurrentUniUserInfo',
        method: 'POST',
        data
    })
}
export default {
    sendLoginSmsCode,
    loginBySmsCode,
    setPassword,
    loginByPwd,
    logout,
    editPassword,
    changeUserPhone,
    getSysConfig,
    loginByWebToken,
    loginByUniAPI,
    syncCurrentUniUserInfoAPI,
}