import {request} from "./request";
// 获取个人信息
function getUserInfo(data) {
    return request({
        url: '/org/user/getUser',
        method: 'POST',
        data: data
    })
}
// 修改个人信息
function userEdit(data) {
    return request({
        url: '/org/user/userEdit',
        method: 'POST',
        data: data
    })
}
// 首次登录进来提交机构用户信息登记表
function firstLoginSave(data) {
    return request({
        url: '/org/user/firstLoginSave',
        method: 'POST',
        data: data
    })
}
// 获取当前用户审批中的机构信息
function getUnapprovalUserUnit(data) {
    return request({
        url: '/org/userUnit/getUnapprovalUserUnit',
        method: 'POST',
        data: data
    })
}
// 获取当前用户的意见反馈数据列表
function selectFeedBackListPage(data) {
    return request({
        url: '/org/feedback/selectListPage',
        method: 'POST',
        data: data
    })
}
// 提交意见反馈
function feedbackSave(data) {
    return request({
        url: '/org/feedback/feedbackSave',
        method: 'POST',
        data: data
    })
}
// 删除意见反馈
function feedbackDelete(data) {
    return request({
        url: '/org/feedback/feedbackDelete',
        method: 'POST',
        data: data
    })
}
// 校验手机号码是否已注册
function phoneCheck(data) {
    return request({
        url: '/org/user/phoneCheck',
        method: 'POST',
        data: data
    })
}
export default {
    userEdit,
    getUserInfo,
    firstLoginSave,
    getUnapprovalUserUnit,
    selectFeedBackListPage,
    feedbackSave,
    feedbackDelete,
    phoneCheck,
}