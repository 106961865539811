import {request} from "./request";
// 字典项数据接口
function getDictTypeList(data) {
    return request({
        url: '/org/dict/getDictTypeList',
        method: 'POST',
        data: data
    })
}
// 随机字典项数据
function getDictTypeRandomList(data) {
    return request({
        url: '/org/dict/getDictTypeRandomList',
        method: 'POST',
        data: data
    })
}
export default {
    getDictTypeList,
    getDictTypeRandomList,
}