<template>
    <div id="app">
        <!-- 路由占位符 -->
        <router-view />
    </div>
</template>
<script>
// WebSocket封装方法
import { sendSock, createWebSocket, closeSock } from '@/utils/websocket'
import { mapState } from 'vuex'
import config from '@/config'

export default {
    components: {},
    data() {
        return {}
    },
    computed: {
        ...mapState(['sysMessageList', 'unReadMessageCnt', 'userInfo', 'aiMessageList'])
    },
    watch: {
        userInfo: {
            handler(val, olVal) {
                if (val.id) {
                    this.init()
                }
            },
            deep: true
        }
    },
    created() {
        if (this.$cookies.get('token')) {
            this.init()
        }
    },
    mounted() {
        var that = this
        // 监听页面打开
        window.addEventListener('load', function () {
            // 获取本地存储中当前打开的网页窗口数量
            let openPageCount = localStorage.getItem('openPageCount')
            // 进行赋值
            openPageCount = openPageCount != 'undefined' && openPageCount != 'null' ? Number(openPageCount) : 0
            openPageCount += 1
            // 增量计算后再次存入本地存储
            localStorage.setItem('openPageCount', openPageCount)
        })
        // 监听页面关闭
        window.addEventListener('beforeunload', function () {
            let openPageCount = localStorage.getItem('openPageCount') != 'undefined' && localStorage.getItem('openPageCount') != 'null' ? localStorage.getItem('openPageCount') : 1
            openPageCount -= 1
            localStorage.setItem('openPageCount', openPageCount)
            localStorage.setItem('beforeUnload_time', new Date().getTime())
        })
        window.addEventListener('unload', async () => {
            let openPageCount = localStorage.getItem('openPageCount') != 'undefined' && localStorage.getItem('openPageCount') != 'null' ? localStorage.getItem('openPageCount') : 1
            let beforeUnload_time = Number(localStorage.getItem('beforeUnload_time'))
            let gap_time = new Date().getTime() - beforeUnload_time
            if (gap_time <= 5 && openPageCount == 0) {
                // 浏览器窗口关闭，且为最后一个窗口时清除用户信息和token
                that.clearCookie()
            }
        })
    },
    beforeDestroy() {
        // 页面销毁时关闭ws。因为有可能ws连接接收数据尚未完成，用户就跳转了页面
        // 在需要主动关闭ws的地方都可以调用该方法
        closeSock()
    },
    methods: {
        clearCookie() {
            this.$cookies.remove('token')
            this.$cookies.remove('userInfo')
        },
        init() {
            createWebSocket(this.global_callback)
        },
        // websocket的回调函数，msg表示收到的消息
        global_callback(res) {
            if (res.messageType == 0) return
            console.log('新消息', res)
            // 系统消息
            if (res.dataType == 'sysmsg') {
                if (res.data.status == 1) {
                    // 添加
                    // 有新消息，且当前页面不是消息中心时，应当对未读数量加1
                    // if(this.$route.path != '/message') {
                    this.$store.commit('renewUnReadMessageCnt', Number(this.unReadMessageCnt) + 1)
                    // }
                    res.data.readState = '0'
                    this.sysMessageList.unshift(res.data)
                    this.$store.commit('renewSysMessageList', this.sysMessageList)
                } else {
                    // 撤回
                    this.sysMessageList.map((item, index) => {
                        if (item.id == res.data.id) {
                            this.sysMessageList.splice(index, 1)
                        }
                    })
                    this.$store.commit('renewSysMessageList', this.sysMessageList)
                    // 当撤回消息，且当前页面不是消息中心时，应当对未读数量减1
                    // if(this.$route.path != '/message') {
                    this.$store.commit('renewUnReadMessageCnt', Number(this.unReadMessageCnt) - 1)
                    // }
                }
            } else if (res.dataType == 'chatmsg') {
                // 互动消息
                this.$store.commit('renewChatMessageList', res.data)
                this.$store.commit('renewUnReadMessageCnt', Number(this.unReadMessageCnt) + 1)
            } else if (res.dataType == 'aichat') {
                // AI问答消息
                if (res.data.finishReason == 'error') {
                    this.$message.warning(res.data.content)
                }
                // 更新会话id
                this.$store.commit('renewAiChatId', res.data.chatId)
                // 更新消息体
                this.$store.commit('renewAiMessageData', res.data)
            }
            // 服务端通知，当前机构咨询人
            if (res.messageType == 6) {
                switch (res.noticeCode) {
                    case '601':
                        // 聊天负责人通知
                        this.$store.commit('renewChatPersonObj', res.data)
                        break
                }
            }
        }
    }
}
</script>
<style lang="less">
#app {
}
</style>

